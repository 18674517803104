<template>
	<div class="page-error">
		<div class="page-error__container">
			<div class="animation-container">
				<div class="animation-space">
					<div class="main-picture">
						<img
							class="main-picture__desktop"
							:src="mainPictureUrl()"
							:alt="mainPictureAlt()"
						>
						<img
							class="main-picture__mobile"
							:src="mainPictureUrl()"
							:alt="mainPictureAlt()"
						>
					</div>
					<div id="left-anim-element" class="orbit">
						<div class="position">
							<div class="planet">
								<img src="/images/errors/planet1.png" alt="">
							</div>
						</div>
					</div>
					<div id="right-anim-element" class="orbit">
						<div class="position position_right">
							<div class="sputnik">
								<img
									:src="additionalPictureUrl()"
									alt=""
								>
							</div>
						</div>
					</div>
				</div>
				<div class="animation-clouds">
					<div class="left-cloud">
						<img src="/images/various/cloud-blue.png" alt="">
					</div>
					<div class="right-cloud">
						<img src="/images/various/cloud-white.png" alt="">
					</div>
				</div>
			</div>
			<div class="page-error__content">
				<div>
					<h2 class="page-error__header">{{ errorHeader() }}</h2>
					<p class="page-error__description">{{ errorDescription() }}</p>
					<NuxtLink class="button" to="/">Перейти на главную</NuxtLink>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import type {NuxtError} from '#app'
import {ref} from 'vue';

const props = defineProps({
	error: Object as () => NuxtError
})

/** Идентификация ширины окна */
const isMobile = ref(false);

/** Код ошибки */
const statusCode = computed(() => props.error!.statusCode);

/** Url картинки */
const mainPictureUrl = () => {
	let filename = '';
	if (statusCode.value) {
		filename = (isMobile.value ? statusCode.value + '-mobile' : statusCode.value).toString();
	}
	else {
		filename = isMobile.value ? 'Error-mobile' : 'Error';
	}

	return `/images/errors/${filename}.png`;
}

/** Описание картинки */
const mainPictureAlt = () => {
	if (statusCode) {
		return `Ошибка ${statusCode}.`
	}
	else {
		return 'Ошибка.'
	}
}

/** Url доп картинки */
const additionalPictureUrl = () => {
	return statusCode ? '/images/errors/sputnik.png' : '/images/errors/planet2.png';
}

/** Заголовок ошибки */
const errorHeader = () => {
	if (500 === statusCode.value) {
		return 'Ошибка сервера';
	}
	else if (503 === statusCode.value) {
		return 'Сервис недоступен.';
	}
	else {
		return 'Страница не найдена';
	}
}

/** Описание ошибки */
const errorDescription = () => {
	if (500 === statusCode.value) {
		return 'Мы уже работаем над устранением проблемы. Скоро вернёмся.';
	}
	else if (503 === statusCode.value) {
		return 'Возможно, он перегружен или ведутся технические работы.';
	}
	else {
		return 'Страница, которую вы ищете, недоступна или удалена.';
	}
}

</script>

<style lang="scss">
@import "assets/styles/error.scss";
</style>
